import React from 'react';
import { Link } from 'react-router-dom';
import PageLayout from '../components/PageLayout';
import Principles from '../components/Principles';
import bridgeImage from '../assets/images/home/bridge.png';
import flowerImage from '../assets/images/home/lonely-flower.png';
import leafImage from '../assets/images/home/leaf.png';
import leafPedalImage from '../assets/images/home/leaf-pedal.png';
import marbleDiskImage from '../assets/images/home/marble-disk.png';
import cubesImage from '../assets/images/home/glass-cubes.png';
import crosshairImage from '../assets/images/home/classifier.png';
import stephenImage from '../assets/images/home/stephen.png';
import arrowRightIcon from '../assets/images/common/arrow-right.svg';

const HomePage = () => {
  const principles = [
    {
      title: 'Empowerment',
      description: 'To reach their full potential, foundations and nonprofits require the right combination of tools and expert guidance. Our solutions enable them to make data-based decisions and amplify their impact.'
    },
    {
      title: 'Innovation',
      description: "We truly believe that technology has the power to change the world for the better. Our cutting-edge solutions support nonprofits' and foundations' efforts to make the world a better place."
    },
    {
      title: 'Integrity',
      description: "When it comes to relationships, we always take the long view. For them to last, they must be rooted in trust and respect. That's why we embrace transparency, honesty, and accountability in all our interactions."
    },
    {
      title: 'Community',
      description: "We strongly believe that foundations and nonprofits will thrive in a vibrant ecosystem where they connect, exchange knowledge, and collaborate. Ultimately, it's what helps us all drive change."
    }
  ];

  return (
    <PageLayout>
      <div className='page-home'>
        <div className='home-section-8'>
          <div className='home-section-8-left'>
            <div className='home-section-8-left-inner-container'>
              <p className='home-section-8-heading'>
                Nurturing foundations and nonprofits with technology and enabling them to bloom
              </p>
              <div>
                <p className='home-section-8-text'>
                  Lotus Rise advances foundations and nonprofits by providing
                  them with the necessary resources and capabilities to thrive.
                </p>
              </div>
            </div>
          </div>
          <div className='home-section-8-right'>
            <img
              src={flowerImage}
              alt='Flower'
              className='home-section-8-image'
            />
          </div>
        </div>

        <div className='home-section-7'>
          <div className='home-section-7-left'>
            <img
              src={bridgeImage}
              alt='Bridge'
              className='home-section-7-photo'
            />
          </div>
          <div className='home-section-7-box'>
            <p className='home-section-7-header'>
              We leverage technology to enable your organization’s success
            </p>
            <p className='home-section-7-sub-header'>
              Here's how you'll benefit from our services.
            </p>
            <div className='home-section-7-subtext-outer-box'>
              <div className='home-section-7-subtext-box'>
                <img
                  src={leafPedalImage}
                  alt='Leaf pedal'
                  className='home-section-7-leaf'
                />
                <p>
                  Our advanced tech solutions are created with foundations and
                  nonprofits in mind.
                </p>
              </div>
              <div className='home-section-7-subtext-box'>
                <img
                  src={leafPedalImage}
                  alt='Leaf Pedal'
                  className='home-section-7-leaf'
                />
                <p>
                  We assist organizations to pilot and adopt modern tech
                  solutions.
                </p>
              </div>
              <div className='home-section-7-subtext-box'>
                <img
                  src={leafPedalImage}
                  alt='Leaf Pedal'
                  className='home-section-7-leaf'
                />
                <p>
                  Our curated services put you in touch with the right
                  resources you need to succeed across the board.
                </p>
              </div>
              <Link className='product-link' to='/services'>
                Learn More
                <img src={arrowRightIcon} alt='Arrow right' />
              </Link>
            </div>
          </div>
        </div>

        <div className='section-principles'>
          <div className='title-wrapper'>
            <p className='title'>Our guiding principles</p>
          </div>
          <div className='principle-list'>
            {principles.map((item) => {
              return (
                <Principles title={item.title} description={item.description} />
              );
            })}
          </div>
        </div>

        <div className='home-section-5' style={{ backgroundColor: '#EEFFE3' }}>
          <div className='home-section-5-left'>
            <div className='home-section-5-left-inner'>
              <img src={leafImage} alt='Leaf Pedal' height={82} width={82} />
              <div className='home-section-5-header'>
                Enhance the predictability of your grant outcomes
              </div>
              <div className='home-section-5-second-text'>
                The Grant Classifier helps you conveniently categorize grants
                using scientific terminology. Fewer manual processes, reduced human error, more efficiency.
              </div>
            </div>
          </div>
          <div className='home-section-5-photo'>
            <img
              src={marbleDiskImage}
              alt='Marbles'
              style={{ width: '100%', height: '100%' }}
            />
          </div>
        </div>

        <div className='home-section-4'>
          <p className='home-section-4-header'>
            Measure your results, and make the right decisions - effortlessly
          </p>
          <div className='home-section-4-first-text'>
            <div className='home-section-4-column'>
              <img
                src={leafPedalImage}
                alt='Leaf Pedal'
                height='18px'
                width='22px'
              />
              <p className='home-section-4-second-text'>
                Remove biases, and make data-informed decisions.
              </p>
            </div>
            <div className='home-section-4-column'>
              <img
                src={leafPedalImage}
                alt='Leaf Pedal'
                height='18px'
                width='22px'
              />
              <p className='home-section-4-second-text'>
                Gain a deeper understanding of your grants' output.
              </p>
            </div>
            <div className='home-section-4-column'>
              <img
                src={leafPedalImage}
                height='18px'
                alt='Leaf Pedal'
                width='22px'
              />
              <p className='home-section-4-second-text'>
                Welcome better workflows, brighter insights, and wiser
                solutions.
              </p>
            </div>
          </div>
          <div className='d-flex justify-content-center'>
            <Link className='product-link' to='/grant-classifier'>
              Learn More
              <img src={arrowRightIcon} alt='Arrow right' />
            </Link>
          </div>
        </div>

        <div className='home-section-3' style={{ backgroundColor: '#EEFFE3' }}>
          <div className='home-section-3-variant'>
            <img
              src={cubesImage}
              alt='Cubes'
              className='home-section-3-cubes-image'
            />
          </div>
          <div className='home-section-3-padding'>
            <img src={crosshairImage} alt='Crosshair' className='crosshair' />
            <div className='home-section-3-top-text'>
              What if foundations could easily measure the impact of their
              grants?
            </div>
            <div className='home-section-3-bottom-text'>
              Learn more about our Grant Impact Reporting tool and how it
              benefits you.
            </div>
            <Link className='product-link' to='/grant-impact-reporting'>
              Learn More
              <img src={arrowRightIcon} alt='Arrow right' />
            </Link>
          </div>
        </div>

        <div className='home-section-2'>
          <p className='home-section-2-text-header'>
            Achieve greater accuracy in the allocation of your grants
          </p>
          <div className='home-section-2-small-header'>
            <div>
              <p className='home-3x1-text'>
                Identify trending topics as early as possible.
              </p>
            </div>
            <div>
              <p className='home-3x1-text'>
                Anticipate which research projects you may want to fund in the
                future.
              </p>
            </div>
            <div>
              <p className='home-3x1-text'>Evaluate proposals at a glance.</p>
            </div>
          </div>
        </div>

        <div className='home-section-1'>
          <div className='home-section-1-variant'>
            <img src={stephenImage} className='stephen' alt='Partner' />
            <div className='contact-info-wrapper'>
              <div className='name'>Steve Fitzmier</div>
              <div className='position'>Director of Planning & Evaluation</div>
              <div className='company'>John Templeton Foundation</div>
            </div>
            <div className='stephen-text'>
              <i>“Partnering with Lotus Rise has helped us to streamline our team’s
              evaluative workflows and to pilot new technologies in ways that
              consistently help us to better understand the impact of our
              funding and to learn how to improve our grantmaking.”</i>
            </div>
          </div>
        </div>
      </div>
    </PageLayout>
  );
};

export default HomePage;
