import React from 'react';
import { BrowserRouter as Router, Route, Routes} from 'react-router-dom';
import HomePage from './pages/HomePage';
import AboutUsPage from './pages/AboutUsPage';
import ProductListPage from './pages/ProductListPage';
import GrantClassifierPage from './pages/GrantClassifierPage';
import ServicesPage from './pages/ServicesPage';
import MarketplacePage from './pages/MarketplacePage';
import FaqPage from './pages/FaqPage';
import ContactPage from './pages/ContactPage';
import GrantImpactReportingPage from './pages/GrantImpactReportingPage';
import BlogPage from './pages/BlogPage';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/products" element={<ProductListPage />} />
        <Route path="/grant-classifier" element={<GrantClassifierPage />} />
        <Route path="/about-us" element={<AboutUsPage />} />
        <Route path="/services" element={<ServicesPage />} />
        <Route path="/grant-impact-reporting" element={<GrantImpactReportingPage />} />
        <Route path="/marketplace" element={<MarketplacePage />} />
        <Route path="/faq" element={<FaqPage />} />
        <Route path="/contact" element={<ContactPage />} />
        <Route path="/blog/:code" element={<BlogPage />} />
      </Routes>
    </Router>
  );
}

export default App;
